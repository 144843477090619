<div class="left-side-menu">
  <div class="">
    <div class="nav-user">
      <a class="nav-link ">
        <img style="width:32px; height: 32px;" src="/assets/fidelidade/icones/user-icon-cinza.png" alt="user-image" class="float-left">
        <div class="dadosUsuario">
          <span class="ml-1"><b>{{usuario.nome | firstWord}}</b></span><br>
          <span class="ml-1 empresa">
           {{usuario.empresa.id}} - {{usuario.empresa.nome}}
         </span>
        </div>

        <span class="tipo-envio" >Envio: {{usuario.empresa.meioDeEnvio}}</span>
        <span class="badge badge-primary ml-1" *ngIf="empresa.dark" >DARK</span>

        <div class="clearfix"></div>
        <div  *ngIf="empresa.linkLoja"  class="clearfix d-inline-block">
           <a href="{{empresa.linkLoja}}" target="_blank"  class="mt-2 mb-1 link-cardapio d-block">
             <i class="fas fa-link"></i>
            <span class="ml-1">Link da Loja</span></a>
        </div>
      </a>
    </div>

    <!--- Sidemenu -->
    <div id="sidebar-menu" [hidden]="!carregou">
      <ul class="metismenu" id="side-menu-w" *ngIf="wizard">
        <li >
          <a   (click)="naveguePara('wizard')" [ngClass]="{'text-primary text-bold':url=='wizard'}" href="javascript: void(0);">
            <i class="k-icon k-i-config"></i>
            <span> Criação da Loja</span>
          </a>
        </li>
      </ul>

      <ul class="metismenu" id="side-menu" *ngIf="!wizard">
        <li *ngIf="!somenteCardapio && !ehGarcom">
          <a   (click)="naveguePara('index')" [ngClass]="{'text-primary text-bold':url=='index'}" href="javascript: void(0);">
            <i class="fe-home"></i>
            <span> Início</span>
          </a>
        </li>
        <li *ngIf="!somenteCardapio && !ehGarcom && visualizarContatos()">
          <a (click)="naveguePara('contatos')" [ngClass]="{'text-primary text-bold':url=='contatos'}" href="javascript: void(0);">
            <i class="fe-users"></i>
            <span> Contatos </span>
          </a>
        </li>

        <li *ngIf="exibirFuncoesPedidos || ehGarcom">
          <a (click)="naveguePara('pedidos-mesas')" [ngClass]="{'text-primary text-bold':url=='pedidos-mesas'}" href="javascript: void(0);">
            <i class="fa fa-table"></i>
            <span> Mesas </span>
            <span class="badge  rounded-circle noti-icon-badge" style="    margin-top: -10px;"
                  [ngClass]="{'badge-danger': resumoMesas.totalNovo >  0,
                          'badge-light': !resumoMesas.totalNovo }">
            {{resumoMesas.totalNovo}}</span>

          </a>

          <ul class="nav-second-level" aria-expanded="false" *ngIf="temIntegracaoParceiro()">
            <li >
              <a  (click)="naveguePara('pedidos-mesas/erros')" [ngClass]="{'text-primary text-bold':url=='pedidos-mesas/erros'}"
                  href="javascript: void(0);"  *ngIf="temIntegracaoParceiro()">
                <b>{{ this.empresa.integracaoDelivery.sistema}}</b> com erro
                <span class="badge  rounded-circle noti-icon-badge  "
                      [ngClass]="{'badge-danger':  resumoPedidos.totalErroParceiroMesa > 0,   'badge-light': !resumoPedidos.totalErroParceiroMesa  }">
                  {{resumoPedidos.totalErroParceiroMesa}}</span>
              </a>

            </li>
          </ul>
        </li>
        <li *ngIf="possuiModuloPDV && visualizarCaixa()">
          <a (click)="naveguePara('meupdv')" [ngClass]="{'text-primary text-bold':url=='meupdv'}" href="javascript: void(0);">
            <i class="fas fa-cash-register"></i>
            <span> Gestão de Caixa </span>
          </a>
        </li>

        <li *ngIf="exibirFuncoesPedidos">
          <a (click)="naveguePara('pedidos')" [ngClass]="{'text-primary text-bold':url=='pedidos'}" href="javascript: void(0);">
            <i class="fe-shopping-cart"></i>
            <span> Pedidos </span>
          </a>
          <ul class="nav-second-level" aria-expanded="false">
            <li *ngIf="temIntegracaoParceiro() || vendeOnline() || integracaoIfood()">
              <a  (click)="naveguePara('pedidos/erros')" [ngClass]="{'text-primary text-bold':url=='pedidos/erros'}"
                  href="javascript: void(0);"  *ngIf="temIntegracaoParceiro()">
                <b>{{ this.empresa.integracaoDelivery.sistema}}</b> com erro
                <span class="badge  rounded-circle noti-icon-badge  "
                      [ngClass]="{'badge-danger':  resumoPedidos.totalErroParceiro > 0,   'badge-light': !resumoPedidos.totalErroParceiro  }">
                  {{resumoPedidos.totalErroParceiro}}</span>
              </a>

              <a  (click)="naveguePara('pedidos/onlineaguardando')" [ngClass]="{'text-primary text-bold':url=='pedidos/onlineaguardando'}"
                  href="javascript: void(0);"  *ngIf="vendeOnline()">
                Online pendentes
                <span class="badge  rounded-circle noti-icon-badge"
                      [ngClass]="{'badge-danger':  resumoPedidos.totalPendenteOnline > 0,   'badge-light': ! resumoPedidos.totalPendenteOnline }">
                   {{resumoPedidos.totalPendenteOnline}}</span>
              </a>

              <ng-container *ngIf="integracaoIfood()">
                <a  (click)="naveguePara('pedidos/contestados')" [ngClass]="{'text-primary text-bold':url=='pedidos/contestados'}"
                    href="javascript: void(0);"   >
                  {{empresa?.integracoesIfood?.length ? 'Ifood ': ''}} Contestações
                  <span class="badge  rounded-circle noti-icon-badge"
                        [ngClass]="{'badge-danger':  resumoPedidos.totalContestados > 0,   'badge-light': ! resumoPedidos.totalContestados }">
                   {{resumoPedidos.totalContestados}}</span>
                </a>

                <a  (click)="naveguePara('pedidos/errosimportar')" [ngClass]="{'text-primary text-bold':url=='pedidos/errosimportar'}"
                    href="javascript: void(0);"   >
                  {{empresa?.integracoesIfood?.length ? 'Ifood ': ''}} Erros Importação
                  <span class="badge  rounded-circle noti-icon-badge" [hidden]="!monitoradorPedidos.falhasImportacao.length "
                        [ngClass]="{'badge-danger':  monitoradorPedidos.falhasImportacao.length > 0,
                         'badge-light': !  monitoradorPedidos.falhasImportacao.length}">
                   {{ monitoradorPedidos.falhasImportacao.length}}</span>
                </a>

              </ng-container>

            </li>

          </ul>
        </li>

        <li *ngIf="exibirProdutos">
          <a (click)="naveguePara('produtos')" [ngClass]="{'text-primary text-bold':url=='produtos'}" href="javascript: void(0);">
            <i class="fas fa-box-open"></i>
            <span> Produtos </span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">

           <!-- <li>
              <a (click)="naveguePara('categorias')" [ngClass]="{'text-primary text-bold':url=='categorias'}" href="javascript: void(0);">
                <span> Categorias </span>
              </a>
            </li> -->

            <li>
              <a (click)="naveguePara('vitrines')" [ngClass]="{'text-primary text-bold':url=='vitrines'}" href="javascript: void(0);">
                <span> Vitrines </span>
              </a>
            </li>

          <!--   <li>
              <a (click)="naveguePara('importar')" [ngClass]="{'text-primary text-bold':url=='importar'}" href="javascript: void(0);">
                <span> Importar </span>
              </a>
            </li> -->
          </ul>
        </li>
        <li *ngIf="exibirEntregadores &&  visualizarLoja()">
          <a (click)="naveguePara('entregadores')" [ngClass]="{'text-primary text-bold':url=='entregadores'}" href="javascript: void(0);">
            <i class="fa fa-motorcycle"></i>
            <span>Entregadores (beta)</span>
          </a>
        </li>


        <li *ngIf="(exibirFuncoesPedidos || exibirProdutos) &&  visualizarLoja()">
          <a (click)="naveguePara('loja')" [ngClass]="{'text-primary text-bold':url=='loja'}" href="javascript: void(0);">
            <i class="far fa-building"></i>
            <span>Loja</span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
            <li>
              <a  (click)="naveguePara('qrcodes')" [ngClass]="{'text-primary text-bold':url=='qrcodes'}"
                  href="javascript: void(0);">
                QR Codes</a>
            </li>
            <li>
              <a  (click)="naveguePara('taxas')" [ngClass]="{'text-primary text-bold':url=='taxas'}"
                  href="javascript: void(0);">
                Hist. Taxas Calculadas</a>
            </li>
            <li>
              <a  (click)="naveguePara('areas-entrega')" [ngClass]="{'text-primary text-bold':url=='areas-entrega'}"
                  href="javascript: void(0);">
                Áreas de Entrega (KML)</a>
            </li>
            <li>
              <a  (click)="naveguePara('temas')" [ngClass]="{'text-primary text-bold':url=='temas'}"
                  href="javascript: void(0);">
                <i class="fas fa-paint-roller"></i>
                Temas</a>
            </li>
          </ul>
        </li>

        <li *ngIf="exibirFuncoesChatbot && visualizarLoja()">
          <a (click)="naveguePara('chatbot/testar')" [ngClass]="{'text-primary text-bold':url=='chatbot/testar'}" href="javascript: void(0);">
            <i class="fa fa-robot"></i>
            <span>MIA - Atendente Virtual</span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
            <li *ngIf="usuario.admin && false">
              <a  (click)="naveguePara('intencoes')" [ngClass]="{'text-primary text-bold':url=='intencoes'}"  href="javascript: void(0);">
                Intenções</a>
            </li>
            <li *ngIf="usuario.admin">
              <a  (click)="naveguePara('templates-prompts-db')" [ngClass]="{'text-primary text-bold':url=='templates-prompts-db'}"  href="javascript: void(0);">
                Templates</a>
            </li>
            <li *ngIf="usuario.admin">
              <a  (click)="naveguePara('chatbot/conversas')" [ngClass]="{'text-primary text-bold':url=='chatbot/conversas'}"  href="javascript: void(0);">
                Conversas</a>
            </li>
            <li *ngIf="usuario.admin">
              <a  (click)="naveguePara('chatbot/chamadas-ia')" [ngClass]="{'text-primary text-bold':url=='chatbot/chamadas-ia'}"  href="javascript: void(0);">
                Chamadas IA</a>
            </li>
            <li>
              <a  (click)="naveguePara('chatbot/analytics')" [ngClass]="{'text-primary text-bold':url=='chatbot/analytics'}"  href="javascript: void(0);">
                Analytics Mia</a>
            </li>
            <li>
              <a  (click)="naveguePara('chatbot/configuracoes')" [ngClass]="{'text-primary text-bold':url=='chatbot/configuracoes'}"  href="javascript: void(0);">
                Configurações</a>
            </li>
            <li>
              <a  (click)="naveguePara('chatbot/testar')" [ngClass]="{'text-primary text-bold':url=='chatbot/testar'}"  href="javascript: void(0);">
                Ferramenta de Testes</a>
            </li>
          </ul>
        </li>

        <ng-container *ngIf="exibirProdutos && visualizaRelatorios()">
          <li >
            <a (click)="naveguePara('financeiro-delivery')" [ngClass]="{'text-primary text-bold':url.indexOf('financeiro') >= 0}"  href="javascript: void(0);">
              <i class="fe-bar-chart-2"></i>
              <span> Financeiro </span>
            </a>

            <ul class="nav-second-level" aria-expanded="false">
              <li>
                <a  (click)="naveguePara('financeiro-delivery')" [ngClass]="{'text-primary text-bold':url=='financeiro-delivery'}"  href="javascript: void(0);">
                  Delivery</a>
              </li>
              <li>
                <a  (click)="naveguePara('financeiro-mesas')" [ngClass]="{'text-primary text-bold':url=='financeiro-mesas'}"  href="javascript: void(0);">
                  Mesas</a>
              </li>

              <li *ngIf="!empresa?.integracaoDelivery">
                <a  (click)="naveguePara('financeiro-balcao')" [ngClass]="{'text-primary text-bold':url=='financeiro-balcao'}"  href="javascript: void(0);">
                  Balcao</a>
              </li>

            </ul>

          </li>

          <li *ngIf="exibirFuncoesFidelidade" >
            <a (click)="naveguePara('fidelidade/pontuacoes')" [ngClass]="{'text-primary text-bold':url.indexOf('pontuacoes') >= 0}"  href="javascript: void(0);">
              <i class="fas fa-medal"></i>
              <span> Pontuações Fidelidade </span>
            </a>
          </li>
        </ng-container>

        <li *ngIf="(exibirFuncoesPedidos || exibirProdutos)">
          <a (click)="naveguePara('avaliacoes')" [ngClass]="{'text-primary text-bold':url=='avaliacoes'}" href="javascript: void(0);">
            <i class="fe-star-on fa-lg" style="color: #f5b106;"></i>
            <span> Avaliações de Pedidos </span>
          </a>
        </li>

        <li>
          <a (click)="naveguePara('analytics')" [ngClass]="{'text-primary text-bold':url=='analytics'}" href="javascript: void(0);">
            <i class="fas fa-users"></i>
            <span> Google Analytics </span>
          </a>
        </li>

        <li *ngIf="visualizaRelatorios() && exibirFuncoesFidelidade" >
          <a (click)="naveguePara('relatorios')" [ngClass]="{'text-primary text-bold':url=='relatorios'}" href="javascript: void(0);">
            <i class="fas fa-chart-pie"></i>
            <span> Inteligência do Negócio </span>
          </a>
        </li>

        <li *ngIf="!ehGarcom && empresa.id === 1">
          <a (click)="naveguePara('whatsapp')" [ngClass]="{'text-primary text-bold':url=='whatsapp'}" href="javascript: void(0);">
            <i class="fab fa-whatsapp fa-lg" style="color: green;font-weight: bold;"></i>
            <span>Integração Whatsapp </span>
          </a>
        </li>

        <li>
          <a (click)="naveguePara('instagram-bot')" [ngClass]="{'text-primary text-bold':url.indexOf('instagram-bot') >= 0}"  href="javascript: void(0);">
            <i class="fas fa-robot fa-lg"></i>
            <span> Robôs </span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
            <li *ngIf="!ehGarcom && possuiModuloInstagramBot">
              <a (click)="naveguePara('instagram-bot')" [ngClass]="{'text-primary text-bold':url=='instagram-bot'}" href="javascript: void(0);">
                <i class="fab fa-instagram fa-lg"></i>
                <span> Instagram BOT </span>
              </a>
            </li>

          </ul>

        </li>
        <li *ngIf="(exibirFuncoesFidelidade || exibirFuncoesPedidos) && visualizarNotificacoes()">
          <a (click)="naveguePara('notificacoes')" [ngClass]="{'text-primary text-bold':url=='notificacoes'}" href="javascript: void(0);">
            <i class="fab fa-whatsapp fa-lg"></i>
            <span> Notificações </span>
          </a>
        </li>

        <li *ngIf="exibirProdutos && visualizarLoja()">
          <a href="javascript: void(0);">
            <i class="fe-bar-chart-2"></i>
            <span> Marketing </span>
          </a>

          <ul class="nav-second-level" aria-expanded="false">
            <li>
              <a  (click)="naveguePara('marketing')" [ngClass]="{'text-primary text-bold':url=='marketing'}"  href="javascript: void(0);">
                <i class="fab fa-whatsapp fa-lg"></i>
                <span> Whatsapp</span></a>
            </li>
            <li *ngIf="possuiModuloApp">
              <a  (click)="naveguePara('marketing-app')" [ngClass]="{'text-primary text-bold':url=='financeiro-mesas'}"  href="javascript: void(0);">
                <i class="fas fa-mobile"></i>
                Notificações APP</a>
            </li>

          </ul>

        </li>

        <li *ngIf="!ehGarcom &&  visualizarNotificacoes()">
          <a (click)="naveguePara('mensagens')" [ngClass]="{'text-primary text-bold':url=='mensagens'}" href="javascript: void(0);">
            <i class="fas fa-inbox"></i>
            <span> Mensagens </span>
          </a>
        </li>

      </ul>
    </div>
    <!-- End Sidebar -->

    <div class="clearfix"></div>
    <!--<button type="button" class="btn btn-primary width-lg mx-auto" style=" display: block;">Criar promoção</button>-->
  </div>
</div>


