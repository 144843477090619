import {ApplicationRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, NgForm, NgModel, ValidatorFn} from "@angular/forms";
import {Location} from "@angular/common";
import {PedidoLocalService} from "../../services/pedido-local.service";
import {PedidoLoja} from "../objetos/PedidoLoja";
import {TipoDePagamentoEnum} from "../objetos/TipoDePagamentoEnum";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {FormaDePagamento} from "../objetos/FormaDePagamento";
import {TelaMesaEscolhidaComponent} from "../tela-mesa-escolhida/tela-mesa-escolhida.component";
import {TaxaCobranca} from "../objetos/TaxaCobranca";
import {TelaExpandidaComponent} from "../TelaExpandida";
import {GridComponent, SelectableSettings} from "@progress/kendo-angular-grid";
import {GridPagamentoComponent} from "../grid-pagamento/grid-pagamento.component";
import {PedidosService} from "../../services/pedidos.service";


@Component({
  selector: 'app-tela-escolher-pagamento',
  templateUrl: './tela-escolher-pagamento.component.html',
  styleUrls: ['./tela-escolher-pagamento.component.scss']
})
export class TelaEscolherPagamentoComponent extends TelaExpandidaComponent implements OnInit {
  @ViewChild('telaMesaEscolhida') telaMesaEscolhida: TelaMesaEscolhidaComponent;
  @ViewChild('gridPagamentos',  {static: true}) gridPagamentos: GridPagamentoComponent;
  contato: any;
  pedido: PedidoLoja;
  tipoDePagamento = TipoDePagamentoEnum;

  @ViewChild('frm', { static: true})  frm: NgForm;
  @ViewChild('grdFormasDePagamento', {static: false}) grdFormasDePagamento: GridComponent;
  @ViewChild('saldoUsarText', { static: false})  saldoUsarText: any;
  @ViewChild('trocoParaTxt', {static: false}) trocoParaTxt: ElementRef;
  telefone: any = '';
  urlRetorno: any;
  tituloPagina: string;
  selecionou: boolean;
  usarSaldo: boolean;
  trocaCashback: any;
  maximoCashback: any;
  formasDePagamento: Array<FormaDePagamento> = [];
  msgErroAdicionar: string
  aplicandoCupom: any;
  erroCupom: any;
  editandoPedido = false;
  constructor(private router: Router, private app: ApplicationRef, private activatedRoute: ActivatedRoute,
              private _location: Location, private pedidoLocalService: PedidoLocalService,
              private constantsService: ConstantsService, private pedidosService: PedidosService) {
    super();
    this.activatedRoute.queryParams.subscribe((queryParams: any) => {
      this.telefone = queryParams.telefone;
      this.urlRetorno = queryParams.urlRetorno;

      this.pedido = this.pedidoLocalService.obtenhaPedido(this.telefone);
      this.editandoPedido = this.pedido.codigo != null;

      if(this.editandoPedido) {
        this.tituloPagina =  'Editar Pedido #' + this.pedido.codigo
      } else
        this.tituloPagina = 'Novo Pedido'

      if(!this.pedido.id && !this.pedido.pagamentos)
        this.pedido.pagamentos = []

      this.constantsService.empresa$.subscribe( (empresa) => {
        if(empresa) {
          this.formasDePagamento = [];
          empresa.formasDePagamento.forEach(formaDePagamento => {
            //const formaDePagamentoPedido = this.pedido.pagamento.formaDePagamento;
            let ehCashback =  formaDePagamento.nome.toLowerCase() === 'cashback';

            if( this.pedido.cashback && ehCashback)
              this.pedido.cashback.formaDePagamento = formaDePagamento;

            if( formaDePagamento.online || ehCashback)
              return;


            this.formasDePagamento.push(formaDePagamento);
          });

          setTimeout(() => {
            if(this.pedido.pagamentos.length)
              this.gridPagamentos.setPagamentos(this.pedido.pagamentos)
          }, 0)

        }
      });
    });
  }

  ngOnInit() {

    this.pedidoLocalService.salveUrlAtual(this.telefone, this.router.url, window.history.state);
    this.recalculeTotal();
    if(this.pedido.cashback && this.pedido.cashback.valor)
      this.usarSaldoCartao();


  }

  onSubmit() {
    this.msgErroAdicionar = this.gridPagamentos.obtenhaErroPagamento();

    if(this.msgErroAdicionar != null) return;
    this.pedido.pagamentos = this.gridPagamentos.pagamentos;


    let totalPago = this.pedido.obtenhaTotalPagamentosSemTaxaDaForma();
    let diferenca = this.pedido.obtenhaTotalPedidoSemTaxaForma() - totalPago    ;

    if(diferenca > 0.1){
      this.msgErroAdicionar =
        String(`O total do pedido (R$ ${this.pedido.total}) é maior que os valores dos pagamentos selecionados (R$ ${totalPago})`);
    }

    if(diferenca < -0.1 &&  !this.pedido.temTroco()){
      this.msgErroAdicionar =
        String(`O total do pedido (R$ ${this.pedido.total}) é menor que os valores dos pagamentos selecionados (R$ ${totalPago})`);
    }

    if (this.frm.valid && !this.msgErroAdicionar) {

      this.pedido.troco = this.gridPagamentos.troco;
      if(this.pedido.temTaxaPagamento() || this.pedido.temDescontoPagamento())
        this.pedido.calculeTotal();

      this.pedidoLocalService.salvePedido(this.pedido, this.telefone);
      this.router.navigate(['/admin/pedidos/novo/confirmar'],
        {
          queryParams: {
            telefone: this.telefone,
            idm: this.telaMesaEscolhida.obtenhaIdMesa(),
            urlRetorno: this.urlRetorno
          }
        }).then(() => {
      });
    }

    this.app.tick();

    return false;
  }

  finalize() {

  }


  voltar() {
    this.pedidoLocalService.removaUrlSalva(this.telefone);
    this._location.back();
  }

  calculeTroco() {
    setTimeout( () => {
      this.pedido.calculeTroco();
    }, 0);
  }

  recalculeTotal() {
    if(!this.pedido.pagamentos || this.pedido.pagamentos.length === 0)
      this.pedido.pagamentos = this.gridPagamentos.pagamentos;
    this.pedido.codigoCupom = null
    this.pedido.cupom = null
    this.pedido.calculeTotal()
    this.gridPagamentos.novoValorPagar(this.pedido.obtenhaValorAhPagar());
  }

  obtenhaSaldoCartao() {
    return this.pedido.obtenhaSaldo()
  }

  usarSaldoCartao() {
    this.usarSaldo = true;

    this.maximoCashback = this.pedido.obtenhaSaldo();

    if(!this.pedido.cashback.valor)
      this.pedido.cashback.valor = this.pedido.cashback.cartao.pontos;

    if( this.pedido.cashback.valor > this.pedido.subTotal   )
      this.pedido.cashback.valor  = this.pedido.subTotal;

    if(this.maximoCashback > this.pedido.subTotal  )
      this.maximoCashback  = this.pedido.subTotal;

    this.recalculeComCashback();

    this.gridPagamentos.novoValorPagar(this.pedido.obtenhaValorAhPagar());

    setTimeout( () => {   this.saldoUsarText.focus()}, 0);
  }


  recalculeComCashback() {
    this.pedido.calculeTotal();
  }

  obtenhaAcrescimo(taxaCobranca: any) {
    return TaxaCobranca.calcule(taxaCobranca, this.pedido.total)
  }

  apliqueCupom() {
    if(!this.pedido.codigoCupom || this.aplicandoCupom) return;

    delete  this.erroCupom;
    this.aplicandoCupom = true;
    this.pedidosService.calculeDescontoCupom(this.pedido.codigoCupom, this.pedido.obtenhaDadosEnvio()).then( (cupom: any) => {
      this.aplicandoCupom = false;
      if(cupom.erro){
        this.erroCupom = cupom.erro;
      }  else {
        this.pedido.apliqueCupom(cupom)
        this.pedidoLocalService.salvePedido(this.pedido, this.telefone);

      }
    }).catch( (erro) => {
      this.aplicandoCupom = false;
      this.erroCupom = 'Ops! nao foi possível verificar o cupom'
    })

  }

  mudouFormaPagamento() {
    this.recalculeTotal();
  }

  alterouOpcaoMultiplasFormas() {
    this.pedido.pagamentos = [];
    if(this.pedido.id && this.pedido.descontoFormaDePagamento)
      this.pedido.descontoFormaDePagamento = 0;

    this.recalculeTotal();
  }
}

export const validadorFormasDePagamento = (field1, field2): ValidatorFn => (control: AbstractControl) => {
  if(control.get(field1).value || control.get(field2).value ) {
    return null;
  }

  return { myValidator: { valid: false } };
}
