import {ProcessadorTexto} from "./ProcessadorTexto";
import {LayoutPedido} from "./LayoutPedido";
import {DatePipe, DecimalPipe} from "@angular/common";
import moment from "moment";

export class ProcessadorReciboTexto extends ProcessadorTexto {
  layout: LayoutPedido;

  constructor(impressora: any) {
    super(impressora);
    this.layout = LayoutPedido.obtenhaLayout(this.papel)
  }

  obtenhaReciboHTML(pedido: any, layoutPedido: string) {
    return "/pedidos/" + pedido.codigo + "/comprovante?resumido=" + (layoutPedido === 'PedidoResumido')
  }

  obtenhaRelatorio(resumoVendas: any) {
    //if(this.impressora.modoHTML)
//      return this.obtenhaRelatorioHTML(resumoVendas)

    let relatorio = this.centralizarLinha(resumoVendas.empresa.nome)


    relatorio += this.linha() + this.linha() + this.centralizarLinha("RELATÓRIO FINANCEIRO")
      + this.linha()
      + this.linha()

    relatorio += this.linha("Data Inicial: " + resumoVendas.dataInicial.toLocaleString())
    relatorio += this.linha("Data Final: " + resumoVendas.dataFinal.toLocaleString())
      + this.linha()

    relatorio += this.linha("RESUMO GERAL")
      + this.linha()


    relatorio += this.linha("Qtde Pedidos: " + resumoVendas.qtde)
    relatorio += this.linha("Total Vendas: " + "R$ " + new DecimalPipe("pt-BR").transform(resumoVendas.totalComTaxa, '1.2-2'))
    relatorio += this.linha("Total Taxa Entrega: " + "R$ " + new DecimalPipe("pt-BR").transform(resumoVendas.taxaDeEntrega, '1.2-2'))
    relatorio += this.linha("Total Produtos: " + "R$ " +
      new DecimalPipe("pt-BR").transform(resumoVendas.total, '1.2-2'))
    relatorio += this.linha("Ticket Médio: " + "R$ " + new DecimalPipe("pt-BR").transform(resumoVendas.ticketMedio, '1.2-2'))
      + this.linha()


    relatorio += this.centralizarLinha('RESUMO POR FORMA DE PAGAMENTO')
      + this.linha()
      + this.linha(
        this.alinhadoEsquerda("FORMA", this.layout.tamanhoColunaDescricao - 1)
        + this.alinhadoEsquerda("QTDE.", this.layout.tamanhoColunaQtd)
        + this.espaco(1)
        + this.alinhadoEsquerda("TOTAL", this.layout.tamanhoColunaValor)
      )


    for (let i = 0; i < resumoVendas.formasDePagamento.length; i++) {
      let forma: any = resumoVendas.formasDePagamento[i];

      let linhasDescricao = this.multiplasLinhas(forma.nome, this.layout.tamanhoColunaDescricao - 3)
      relatorio += this.linha(
        this.alinhadoEsquerda(linhasDescricao[0], this.layout.tamanhoColunaDescricao - 3)
        + this.espaco(1)
        + this.alinhadoDireita(forma.qtde.toString(), this.layout.tamanhoColunaQtd)

        + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(forma.total, '1.2-2'),
          this.layout.tamanhoColunaValor + 1)
      )

    }

    relatorio += this.linha()
    relatorio += this.centralizarLinha('RESUMO POR FORMA DE ENTREGA')
      + this.linha()
      + this.linha(
        this.alinhadoEsquerda("FORMA", this.layout.tamanhoColunaDescricao - 1)
        + this.alinhadoEsquerda("QTDE.", this.layout.tamanhoColunaQtd)
        + this.espaco(1)
        + this.alinhadoEsquerda("TOTAL", this.layout.tamanhoColunaValor)
      )

    for (let i = 0; i < resumoVendas.formasDeEntrega.length; i++) {
      let forma: any = resumoVendas.formasDeEntrega[i];

      let linhasDescricao = this.multiplasLinhas(forma.nome, this.layout.tamanhoColunaDescricao - 3)
      relatorio += this.linha(
        this.alinhadoEsquerda(linhasDescricao[0], this.layout.tamanhoColunaDescricao - 3)
        + this.espaco(1)
        + this.alinhadoDireita(forma.qtde.toString(), this.layout.tamanhoColunaQtd)

        + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(forma.total, '1.2-2'),
          this.layout.tamanhoColunaValor + 1)
      )
    }

    relatorio += this.linha()
    if (resumoVendas.cupons && resumoVendas.cupons.length > 0) {
      relatorio += this.centralizarLinha('RESUMO POR CUPONS')
        + this.linha()
        + this.linha(
          this.alinhadoEsquerda("FORMA", this.layout.tamanhoColunaDescricao - 1)
          + this.alinhadoEsquerda("QTDE.", this.layout.tamanhoColunaQtd)
          + this.espaco(1)
          + this.alinhadoEsquerda("TOTAL", this.layout.tamanhoColunaValor)
        )

      for (let i = 0; i < resumoVendas.cupons.length; i++) {
        let cupom: any = resumoVendas.cupons[i];

        let linhasDescricao = this.multiplasLinhas(cupom.nome, this.layout.tamanhoColunaDescricao - 3)
        relatorio += this.linha(
          this.alinhadoEsquerda(linhasDescricao[0], this.layout.tamanhoColunaDescricao - 3)
          + this.espaco(1)
          + this.alinhadoDireita(cupom.qtde.toString(), this.layout.tamanhoColunaQtd)

          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(cupom.total, '1.2-2'),
            this.layout.tamanhoColunaValor + 1)
        )


      }
    }

    return relatorio
  }


  obtenhaReciboUmPorProduto(pedido: any) {
    let layoutPedido = "UmPorProduto"

    let inicioRecibo = this.centralizarLinha(pedido.empresa.nome)

    inicioRecibo = this.obtenhaCabecalhoPedido(inicioRecibo, pedido);

    inicioRecibo = this.imprimaDadosCliente(inicioRecibo, pedido)
      + this.linha();

    inicioRecibo = this.imprimaHorarioAgendado(pedido, inicioRecibo);

    let fimRecibo = ""

    this.imprimaRodape(fimRecibo, pedido, layoutPedido)

    fimRecibo += ProcessadorReciboTexto._MARCADOR_FIM_PAPEL

    let recibo = ""


    for (let i = 0; i < pedido.itens.length; i++) {
      recibo += inicioRecibo

      let item: any = pedido.itens[i];

      if (item.pedido && item.pedido.cancelado) continue;

      let linhasDescricao = this.multiplasLinhas(item.descricao, this.layout.tamanhoColunaDescricao - 2 +
        (layoutPedido === 'PedidoCompleto' ? 0 : this.layout.tamanhoColunaValor))
      recibo += this.linha(
        this.alinhadoEsquerda(linhasDescricao[0], this.layout.tamanhoColunaDescricao - 2 +
          (layoutPedido === 'PedidoCompleto' ? 0 : this.layout.tamanhoColunaValor))
        + this.espaco(2)
        + this.alinhadoDireita(item.descricaoQtde, this.layout.tamanhoColunaQtd)

        + (layoutPedido === 'PedidoCompleto' ? (!item.troca ?
            this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(item.total, '1.2-2'),
              this.layout.tamanhoColunaValor)
            : this.alinhadoDireita(new DecimalPipe("pt-BR").transform(item.valor, '1.2-2') + " pts", this.layout.tamanhoColunaValor)

        ) : "")
      )

      if (linhasDescricao.length > 1)
        for (let j = 1; j < linhasDescricao.length; j++)
          recibo += this.linha(linhasDescricao[j])

      if (item.adicionaisImprirmir && item.adicionaisImprirmir.length > 0) {
        let mapaAdicionais: any = {}

        let definicaoAdicionalAtual = null
        let imprimiuTitulo = false
        for (let k = 0; k < item.adicionaisImprirmir.length; k++) {
          let adicional = item.adicionaisImprirmir[k]
          if (pedido.agruparAdicionais && adicional.adicional &&
            (!definicaoAdicionalAtual || definicaoAdicionalAtual.id !== adicional.adicional.id)) {
            definicaoAdicionalAtual = adicional.adicional
            imprimiuTitulo = false;
          }


          if (definicaoAdicionalAtual && !imprimiuTitulo) {
            recibo += this.linha(this.espaco(1) + this.alinhadoEsquerda(definicaoAdicionalAtual.nome,
              this.layout.tamanhoColunaDescricao - 2))
            imprimiuTitulo = true
          }


          let linhasAdicional = this.multiplasLinhas(adicional.descricao +
            (k === item.adicionaisImprirmir.length - 1 ? '' : ',')
            , this.layout.tamanhoColunaDescricao - 3)

          for (let textoLinha of linhasAdicional)
            recibo += this.linha(this.espaco(definicaoAdicionalAtual ? 2 : 1) + this.alinhadoEsquerda(textoLinha,
              this.layout.tamanhoColunaDescricao -
              (definicaoAdicionalAtual ? 3 : 2)))
        }
      }


      if (item.observacao) {
        let observacao = item.observacao.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        let linhasObservacao = this.multiplasLinhas('"' + observacao + '"', this.layout.tamanhoColunaDescricao - 3)

        for (let linhaObservacao of linhasObservacao)
          recibo += this.linha(this.espaco(1) + this.alinhadoEsquerda(linhaObservacao, this.layout.tamanhoColunaDescricao - 2))
      }

      recibo += this.linha()

      recibo += fimRecibo
    }

    return recibo;
  }


  obtenhaRecibo(pedido: any, layoutPedido: string) {
    console.log(pedido)
    if (layoutPedido === 'UmPorProduto')
      return this.obtenhaReciboUmPorProduto(pedido)

    if (this.impressora.modoHTML)
      return this.obtenhaReciboHTML(pedido, layoutPedido)


    let descricaoPedido = 'Detalhes do Pedido';

    if (pedido.mesa)
      descricaoPedido = pedido.empresa.identificadorMesa + " " + pedido.mesa.nome + " - " + descricaoPedido


    let recibo = this.centralizarLinha(pedido.empresa.nome)
    //INÍCIO
    if (layoutPedido === 'PedidoCompleto') {
      recibo += this.centralizarLinha("Comprovante de Pedido")
        + this.linha()
        + this.linha()
    }
    //CABEÇALHO
    recibo = this.obtenhaCabecalhoPedido(recibo, pedido);

    //DADOS CLIENTE
    if (layoutPedido === 'PedidoCompleto') {
      recibo = this.imprimaDadosCliente(recibo, pedido);

      if(pedido.cliente && pedido.cliente.nome)
        recibo += this.linha(String(`Quantidade de pedidos: ${pedido.cliente.qtdePedidos}`))
        + this.linha();

      console.log(pedido.formaDeEntrega)

      for (let linha of this.multiplasLinhas(pedido.formaDeEntrega ?
          (pedido.retirar ? pedido.mensagemRetirada : "Endereço de entrega: " + pedido.enderecoCompleto) : '',
        this.papel.tamanhoLinha))
        recibo += this.linha(linha)

      recibo += this.linha()
    }
    recibo = this.imprimaHorarioAgendado(pedido, recibo);

    recibo += this.centralizarLinha(descricaoPedido)
      + this.linha()
      + this.linha(
        this.alinhadoEsquerda("DESCRIÇÃO", this.layout.tamanhoColunaDescricao +
          (layoutPedido === 'PedidoCompleto' ? 0 : this.layout.tamanhoColunaValor - 1))
        + this.alinhadoEsquerda("QTDE.", this.layout.tamanhoColunaQtd)
        + this.espaco(1)
        + (layoutPedido === 'PedidoCompleto' ? this.alinhadoEsquerda("TOTAL",
            this.layout.tamanhoColunaValor - 1) :
          "")
      )

    for (let i = 0; i < pedido.itens.length; i++) {
      let item: any = pedido.itens[i];

      if (item.pedido && item.pedido.cancelado) continue;

      if(layoutPedido === 'PedidoResumido' && !this.impressora.imprimirAutomatico)
        if(this.impressora.produtos && this.impressora.produtos.length)//nao imprimi no resumido itens fora da categoria
           if(!this.impressora.produtos.find((produto: any) => produto.id === item.produto.id))
               continue

      let linhasDescricao = this.multiplasLinhas(item.descricao, this.layout.tamanhoColunaDescricao - 2 +
        (layoutPedido === 'PedidoCompleto' ? 0 : this.layout.tamanhoColunaValor))
      recibo += this.linha(
        this.alinhadoEsquerda(linhasDescricao[0], this.layout.tamanhoColunaDescricao - 2 +
          (layoutPedido === 'PedidoCompleto' ? 0 : this.layout.tamanhoColunaValor))
        + this.espaco(2)
        + this.alinhadoDireita(item.descricaoQtde, this.layout.tamanhoColunaQtd)

        + (layoutPedido === 'PedidoCompleto' ? (!item.troca ?
            this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(item.total, '1.2-2'),
              this.layout.tamanhoColunaValor)
            : this.alinhadoDireita(new DecimalPipe("pt-BR").transform(item.valor, '1.2-2') + " pts", this.layout.tamanhoColunaValor)

        ) : "")
      )

      if (linhasDescricao.length > 1)
        for (let j = 1; j < linhasDescricao.length; j++)
          recibo += this.linha(linhasDescricao[j])

      if (item.adicionaisImprirmir && item.adicionaisImprirmir.length > 0) {
        let mapaAdicionais: any = {}

        let definicaoAdicionalAtual = null
        let imprimiuTitulo = false
        for (let k = 0; k < item.adicionaisImprirmir.length; k++) {
          let adicional = item.adicionaisImprirmir[k]
          if (pedido.agruparAdicionais && adicional.adicional &&
            (!definicaoAdicionalAtual || definicaoAdicionalAtual.id !== adicional.adicional.id)) {
            definicaoAdicionalAtual = adicional.adicional
            imprimiuTitulo = false;
          }


          if (definicaoAdicionalAtual && !imprimiuTitulo) {
            recibo += this.linha(this.espaco(1) + this.alinhadoEsquerda(definicaoAdicionalAtual.nome,
              this.layout.papel.tamanhoLinha - 2))
            imprimiuTitulo = true
          }


          let linhasAdicional = this.multiplasLinhas(adicional.descricao +
            (k === item.adicionaisImprirmir.length - 1 ? '' : ',')
            , this.layout.papel.tamanhoLinha - 3)

          for (let textoLinha of linhasAdicional)
            recibo += this.linha(this.espaco(definicaoAdicionalAtual ? 2 : 1) + this.alinhadoEsquerda(textoLinha,
              this.layout.papel.tamanhoLinha -
              (definicaoAdicionalAtual ? 3 : 2)))
        }
      }


      if (item.observacao) {
        let observacao = item.observacao.replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
        let linhasObservacao = this.multiplasLinhas('"' + observacao + '"', this.layout.papel.tamanhoLinha - 3)

        for (let linhaObservacao of linhasObservacao)
          recibo += this.linha(this.espaco(1) + this.alinhadoEsquerda(linhaObservacao, this.layout.papel.tamanhoLinha - 2))
      }

      recibo += this.linha()
    }

    if (layoutPedido === 'PedidoCompleto') {
      recibo += this.linha()
        + this.linha(this.alinhadoDireita("Total produtos:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.subvalor, '1.2-2'), this.layout.tamanhoColunaValor));

      if (!pedido.mesa)
        recibo
          += this.linha(this.alinhadoDireita("Taxa de Entrega:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.taxaEntrega +
            pedido.descontoTaxaEntrega, '1.2-2'), this.layout.tamanhoColunaValor))

      if (pedido.descontoTaxaEntrega)
        recibo += this.linha(this.alinhadoDireita(
            pedido.cupom ? "Cupom " + pedido.cupom + ":" : "Desconto taxa:",
            this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(
            0 - pedido.descontoTaxaEntrega, '1.2-2'), this.layout.tamanhoColunaValor))


      if (pedido.taxaFormaDePagamento) {
        recibo += this.linha(this.alinhadoDireita("Taxa forma de pagamento:",
            this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.taxaFormaDePagamento, '1.2-2'),
            this.layout.tamanhoColunaValor))
      }

      if(pedido.ifood && pedido.ifood.taxaServico){
        recibo += this.linha(this.alinhadoDireita("Taxa de serviço Ifood:",
            this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.ifood.taxaServico, '1.2-2'),
            this.layout.tamanhoColunaValor))
      }

      if (pedido.desconto) {
        let label = "Descontos:"

        if(pedido.patrocinadoresDesconto)
           label  = String(`Descontos (${pedido.patrocinadoresDesconto}) :`)

        if (pedido.cupom && !pedido.descontoTaxaEntrega) label = "Cupom " + pedido.cupom;

        recibo += this.linha(this.alinhadoDireita(label, this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(0 - pedido.desconto, '1.2-2'), this.layout.tamanhoColunaValor))

      }

      if (pedido.descontoFormaDePagamento) {
        let labelDFP = pedido.descricaoDescontoFormaDePagamento;

        recibo += this.linha(this.alinhadoDireita(labelDFP, this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR")
            .transform(0 - pedido.descontoFormaDePagamento, '1.2-2'), this.layout.tamanhoColunaValor))


      }

      if (pedido.cashback)
        recibo += this.linha(this.alinhadoDireita("Descontos (cashback):",
            this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(0 - pedido.cashback, '1.2-2'), this.layout.tamanhoColunaValor))


      if (pedido.cobrarTaxaServico) {
        recibo += this.linha(this.alinhadoDireita("Taxa Servico:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
            + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.taxaServico, '1.2-2'), this.layout.tamanhoColunaValor))
          + this.linha()


        recibo += this.linha()
          + this.linha(this.alinhadoDireita("Total com Taxa:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
            + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.totalComTaxa,
              '1.2-2'), this.layout.tamanhoColunaValor))
          + this.linha()
      } else {

        recibo += this.linha()
          + this.linha(this.alinhadoDireita("Total:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
            + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pedido.total, '1.2-2'), this.layout.tamanhoColunaValor))
          + this.linha()

      }


      recibo += this.linha(this.alinhadoDireita("Pagamento:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
          + (pedido.pago ?
              this.alinhadoDireita('Pago', this.layout.tamanhoColunaValor)
              : this.alinhadoDireita('Cobrar do cliente na entrega', this.layout.tamanhoColunaValor)
          ))
        + this.linha()

      let tamanhoColuna = pedido.pagamentos.length > 1 ? this.papel.tamanhoLinha :
        this.papel.tamanhoLinha - this.layout.tamanhoColunaDescricao

      if (pedido.pagamentos.length === 1)
        recibo += this.alinhadoDireita("Pagamento em:", this.layout.tamanhoColunaDescricao)
      else if (pedido.pagamentos.length)
        recibo += this.linha(this.alinhadoDireita("Formas de Pagamento:"))

      if (!pedido.foiPagoOnline || pedido.pagamentos.length > 1) {
        for (let l = 0; l < pedido.pagamentos.length; l++) {
          let pagamento = pedido.pagamentos[l]

          recibo += this.alinhadoDireita(pagamento.formaDePagamento.descricao + (pedido.pagamentos.length > 1 ?
              "(" + "R$ " + new DecimalPipe("pt-BR").transform(pagamento.valor, '1.2-2') + ")" : ''), tamanhoColuna)
            + this.linha()

          if (pagamento.levarTroco) {
            recibo += this.linha(this.alinhadoDireita("Troco para:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
                + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pagamento.trocoPara,
                  '1.2-2'), this.layout.tamanhoColunaValor))
              + this.linha(this.alinhadoDireita("Valor do troco:", this.layout.tamanhoColunaDescricao + this.layout.tamanhoColunaQtd)
                + this.alinhadoDireita("R$ " + new DecimalPipe("pt-BR").transform(pagamento.valorTroco,
                  '1.2-2'), this.layout.tamanhoColunaValor))
          }
        }
      }

      if (pedido.entregador) {
        recibo += this.alinhadoDireita("Entregador:", this.layout.tamanhoColunaDescricao)
        recibo += this.alinhadoDireita(pedido.entregador, this.papel.tamanhoLinha - this.layout.tamanhoColunaDescricao)
          + this.linha()
      }

      if (pedido.foiPagoOnline)
        recibo += this.linha() + this.linha(this.alinhadoDireita('Pedido pago online, nao cobrar do cliente'))


    }

    recibo += this.linha()



    recibo = this.imprimaRodape(recibo, pedido, layoutPedido)

    return recibo

  }

  private imprimaRodape(recibo: string, pedido: any, layoutPedido: string){
    if (layoutPedido === 'PedidoCompleto') {
      if (pedido.observacoes || (pedido.ifood && pedido.ifood.cpfNota)){
        recibo += this.linha() + 'Observação: '
        if(pedido.observacoes)
          recibo +=  "'" + pedido.observacoes + "'" + this.linha()

        if( (pedido.ifood && pedido.ifood.cpfNota))
          recibo +=  this.linha() + " Incluir cpf na nota fiscal: " +   pedido.ifood.cpfNota
      }

      if (pedido.pontosGanhos) {
        recibo += this.linha() +
          this.alinhadoDireita(String(`Pontuação fidelidade: ${pedido.pontosGanhosDescricao}`));
      }

      recibo += this.linha() +
        this.centralizarLinha('Assinatura')
    } else {
      if(pedido.observacoes)
        recibo += this.linha() + 'Observação: "' + pedido.observacoes +  '"' + this.linha()
    }

    recibo += this.linha() + (pedido.operador ? "Operador: " + pedido.operador.nome + " em " + pedido.horarioDescricao : "")
      + this.linha()

    recibo += this.multiplasLinhas(
      "Horário da impressão: " + pedido.agoraDescricao, this.papel.tamanhoLinha).join("\n")

    return recibo;
  }

  private imprimaDadosCliente(recibo: string, pedido: any) {
    if(pedido.cliente && pedido.cliente.nome){
      recibo += this.linha(this.alinhadoEsquerda("Cliente", this.layout.tamanhoColunaCliente) +
          (  this.alinhadoEsquerda("Telefone", this.layout.tamanhoColunaTelefone)))
        + this.linha(this.alinhadoEsquerda(pedido.cliente.nome, this.layout.tamanhoColunaCliente) +
          ( this.alinhadoEsquerda(pedido.cliente.telefoneFormatado, this.layout.tamanhoColunaTelefone)));
    }

    return recibo;
  }

  private imprimaHorarioAgendado(pedido: any, recibo: string) {
    if (pedido.horarioEntregaAgendada) {
      recibo += this.linha('Pedido agendado para ' + new DatePipe("pt-BR").transform(pedido.horarioEntregaAgendada, "dd/MM/yyyy HH:mm"))
        + this.linha()
    }

    if(   pedido.ifood && pedido.ifood.localizadorPedido){
      recibo += this.alinhadoDireita("Localizador Pedido:", this.layout.tamanhoColunaDescricao)
      recibo += this.alinhadoDireita(pedido.ifood.localizadorPedido, this.papel.tamanhoLinha - this.layout.tamanhoColunaDescricao)
        + this.linha()
    }


    return recibo;
  }

  private obtenhaCabecalhoPedido(recibo: string, pedido: any) {
    recibo += this.linha(this.alinhadoEsquerda("Código", this.layout.tamanhoColunaCodigo) +
        this.alinhadoEsquerda("Data", this.layout.tamanhoColunaData))
      + this.linha(this.alinhadoEsquerda('#' + pedido.codigo, this.layout.tamanhoColunaCodigo) +
        this.alinhadoEsquerda(pedido.horarioDescricao, this.layout.tamanhoColunaData))
      + this.linha()
    return recibo;
  }

  private obtenhaRelatorioHTML(resumoVendas: any) {
    let query = []

    if (resumoVendas.dataInicial)
      query.push('di=' + moment(resumoVendas.dataInicial).format('YYYYMMDD'))

    if (resumoVendas.dataFinal)
      query.push('df=' + moment(resumoVendas.dataFinal).format('YYYYMMDD'))

    return "/vendas/resumo/relatorio" + (query.length > 0 ? '?' + query.join("&") : '')
  }

}
