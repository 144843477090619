import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServerService} from "../../services/ServerService";

@Injectable({
  providedIn: 'root'
})
export class ContratoService  extends  ServerService {

  constructor(http: HttpClient) {
    super(http)
  }

  obtenhaContrato(empresa: any) {
    return this.http.get("/contratos/" + empresa.id, {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveContrato(empresa: any, contrato: any) {
    return this.salve('/contratos/' + empresa.id, contrato)
  }

  altereDataVencimento(contrato: any) {
    return this.salve('/contratos/' + contrato.id + '/datavencimento', {
      id: contrato.id,
      dataVencimento: contrato.dataNovoVencimento
    })
  }

  alterarDataAtivacao(contrato: any, dataAtivacao: any) {
    return this.salve('/contratos/' + contrato.id + '/ativacao', {id: contrato.id, dataAtivacao: dataAtivacao})
  }

  altereDataFimTrial(contrato: any, dataFimTrial: any) {
    return this.salve('/contratos/' + contrato.id + '/datafimtrial', {id: contrato.id, dataFimTrial: dataFimTrial})
  }

  alterePlano(contrato: any, novoPlano: any){
    return this.salve('/contratos/' + contrato.id + '/plano', {id: contrato.id, plano: novoPlano})
  }
  altereLimiteContatos(contrato: any, limitecontatos: any) {
    return this.salve('/contratos/' + contrato.id + '/limitecontatos', {id: contrato.id, limitecontatos: limitecontatos})
  }

  altereNumeroParcelas(contrato: any, numeroParcelas: any) {
    return this.salve('/contratos/' + contrato.id + '/numeroparcelas', {id: contrato.id, numeroparcelas: numeroParcelas})
  }

  alterarValorNegociado(contrato: any, valornegociado: any) {
    return this.salve('/contratos/' + contrato.id + '/valornegociado', {id: contrato.id, valornegociado: valornegociado})
  }

  alterarTaxaAdesao(contrato: any, taxaAdesao: any) {
    return this.salve('/contratos/' + contrato.id + '/taxaadesao', {id: contrato.id, taxaAdesao: taxaAdesao})
  }

  altereFormaPagamento(assinatura: any, novaFormaPagamento: any) {
    return this.salve('/contratos/assinaturas/formapagamento', {id: assinatura.id, fp: novaFormaPagamento.join(',')})
  }

  gereBoleto(fatura: any) {
    return this.http.post('/mercadopago/boleto/gere/' + fatura.id, {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  canceleBoleto(pagamento: any) {
    return this.http.post('/mercadopago/pagamento/' + pagamento.codigo + "/cancele", {})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  canceleFatura(fatura: any) {
    return this.http.put('/contratos/faturas/cancele', {id: fatura.id})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  salveLancamento(fatura: any, lancamento: any) {
    return this.salve('/contratos/faturas/lancamento', {id: fatura.id, lancamento: lancamento})
  }

  excluaLancamento(fatura: any, lancamento: any) {
    return this.salve('/contratos/faturas/lancamento/remova', {id: fatura.id, lancamento: lancamento})
  }


  crieAssinatura(empresa: any, formasDePagamento: any) {
    return this.http.post('/contratos/' + empresa.id + "/assinatura", {fp: formasDePagamento.join(','), eid: empresa.id})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  crieFatura(codigo: string) {
    return this.http.post('/contratos/assinaturas/fatura', {codigo: codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  crieFaturaAdesao(empresa: any, taxaAdesao: number, formaDePagamento: string) {
    let dados = {empresa: empresa, taxaAdesao: taxaAdesao, formaDePagamento: formaDePagamento };
    return this.http.post('/contratos/fatura/adesao', dados)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  sincrozeAssinatura(contrato: any, codigo: string) {
    return this.http.put(String(`/contratos/${contrato.id}/assinaturas/sincronize`), {codigo: codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  associeAssinaturaExistente(contrato: any, codigo: string) {
    return this.http.put('/contratos/assinaturas/associe', {cid: contrato.id, codigo: codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }
  associeAssinaturaPai(contrato: any, codigo: string) {
    return this.http.put('/contratos/assinaturas/associe/pai', {cid: contrato.id, codigo: codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  suspendaAssinatura(contrato: any, assinatura: any) {
    return this.http.put('/contratos/assinaturas/suspenda', { cid: contrato.id, codigo: assinatura.codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  reativeAssinatura(contrato: any, assinatura: any, novoVencimento: Date) {
    return this.http.put('/contratos/assinaturas/ative', { cid: contrato.id, codigo: assinatura.codigo, novoVencimento: novoVencimento})
      .toPromise().then(this.retorno).catch(this.handleError);
  }


  informePagamentoFatura(fatura: any) {
    return this.http.put('/contratos/faturas/informepagamento', {fid: fatura.id, dtpg: fatura.dataPagamentoInformada})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  sincronizeFatura(fatura: any) {
    return this.http.put('/contratos/faturas/sincronize', {codigo: fatura.codigo})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  ativeContratoViaCartao(contrato: any, cartao: any) {
    return this.http.put('/contratos-ativacao/cartao/ative', {cartao: cartao, id: contrato.id})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  vinculeCartaoAssinatura(contrato: any,   cartao: any, fatura: any = null) {
    return this.http.put('/contratos-ativacao/cartao/vincule', { cartao: cartao, id: contrato.id , fatura: fatura })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  executeNovaCobranca(fatura: any) {
    return this.http.put('/contratos/faturas/novatentativapagamento', fatura)
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  removaFomaDePagamento(empresa: any, cartao: any){
    return this.http.put('/contratos/cartao/remova', {empresa: empresa, cartao: cartao})
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  obtenhaFaturaAberta(){
    return this.http.get('/contratos/faturas/aberta', {  })
      .toPromise().then(this.retorno).catch(this.handleError);
  }

  adicioneItemAssinatura(contrato: any, novoItem: any) {
    return this.http.put('/contratos/assinaturas/add', {cid: contrato.id, codigo: contrato.assinatura.codigo, item: novoItem})
      .toPromise().then(this.retorno).catch(this.handleError);

  }
  removaItemAssinatura(contrato: any, item: any) {
    return this.http.put('/contratos/assinaturas/remova', {cid: contrato.id, codigo: contrato.assinatura.codigo, item: item})
      .toPromise().then(this.retorno).catch(this.handleError);

  }
}
