import {Component, Input, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent, RemoveEvent} from "@progress/kendo-angular-grid";
import {ProdutoService} from "../../services/produto.service";
import {CadProdutoComponent} from "../cad-produto/cad-produto.component";
import {ConstantsService} from "../../fidelidade/ConstantsService";
import {OrdenarCategoriasComponent} from "../ordenar-categorias/ordenar-categorias.component";
import {process, State} from "@progress/kendo-data-query";
import {ImportadorCsvComponent} from "../../importador-csv/importador-csv.component";
import {CadTemplatepizzaComponent} from "../../compartilhado/cad-templatepizza/cad-templatepizza.component";
import {EmpresasService} from "../services/empresas.service";
import {TelaSincronizarPrecosComponent} from "../../compartilhado/tela-sincronizar-precos/tela-sincronizar-precos.component";
import {TelaSincronizarEstoqueComponent} from "../../compartilhado/tela-sincronizar-estoque/tela-sincronizar-estoque.component";

import {TelaSincronizarIfoodComponent} from "../../compartilhado/tela-sincronizar-ifood/tela-sincronizar-ifood.component";
import {TelaImportarProdutosERPComponent} from "../../compartilhado/tela-importarerp/tela-importar-produtos-erp.component";
import {TelaSincronizarChinaComponent} from "../../compartilhado/tela-sincronizar-china/tela-sincronizar-china.component";
import {AutorizacaoService} from "../../services/autorizacao.service";
import {DisponibilidadeService} from "../../compartilhado/services/disponibilidade.service";
import {
  TelaSincronizarCatalogomodeloComponent
} from "../../compartilhado/tela-sincronizar-catalogomodelo/tela-sincronizar-catalogomodelo.component";
import {
  PayloadJsonViewComponent
} from "../../pedidos/tela-pedidos-erros-importar/payload-json-view/payload-json-view.component";
import {HttpClient} from "@angular/common/http";
import {RotaGuardClient} from "../../guards/auth-loja.guard";
import {DialogRef, DialogService} from "@progress/kendo-angular-dialog";
import {KendoPopupUtils} from "../../lib/KendoPopupUtils";
import {ModalExportarProdutosComponent} from "../../compartilhado/modal-exportar-produtos/modal-exportar-produtos.component";


declare var _;

@Component({
  selector: 'app-produtos2',
  templateUrl: './produtos2.component.html',
  styleUrls: ['./produtos2.component.scss']
})
export class Produtos2Component implements OnInit {
  pageSize = 20;
  pageSizes: any = [10, 20, 30, 40, 50, 100]
  skip = 0;
  ordenando: boolean
  total = 0;
  confirmarReordenacao: boolean;
  public gridDataResult: GridDataResult;
  public state: State = {
    skip: 0,
    take: 500
  };

  private timerBusca;
  produtos: any = [];
  abraDialogo: boolean;
  produtoSelecionado: any;
  excluindoProduto: boolean;
  mensagemRemocao: string;
  podeEditarProdutos: any = true;
  visible: any;
  removeu: boolean;
  chaves: any = [true];
  usuario: any = {}
  filtroDisponibilidades: any = [];
  diasDaSemana  = [
    { dia: 0 , label: 'Dom' },
    { dia: 1 , label: 'Seg' },
    { dia: 2 , label: 'Ter' },
    { dia: 3 , label: 'Qua'},
    { dia: 4 , label: 'Qui' },
    { dia: 5 , label: 'Sex' },
    { dia: 6 , label: 'Sab' } ]

  produtosPorEstoque = [
    {
      id: 'tem-estoque',
      nome: 'À venda'
    },
    {
      id: 'sem-estoque',
      nome: 'Indisponíveis'
    },
  ];
  carregando = false;
  estoqueSelecionado = this.produtosPorEstoque[0].nome;
  filtro: any = {};
  trocandoOrdem: boolean;
  templates = [];
  produtosPorCategoria: any = {};
  categorias: any  = [];
  tagsAlimentar: any  = [];
  categoriaSelecionada: any = {};
  categoriasDoCatalogo = [];
  processando = false;
  categoriaTodas = {
    nome: 'Todas'
  };

  largura: any;

  opcoesSubir = [
    {
      text: 'Subir 1 posição',
      valor: 'SUBIR_1'
    },
    {
      text: 'Subir para Topo da Categoria',
      valor: 'SUBIR_TOPO'
    }
  ];

  opcoesDescer = [
    { text: 'Descer 1 posição', valor: 'DESCER_1'},
    { text: 'Descer para final da Categoria', valor: 'DESCER_TUDO' }
  ];

  settingsSincronizar: Array<any> = [
    { text: 'Preços' },
    { text: 'Estoque' }
  ];

  private _catalogo: any;
  buscouComFiltro: boolean;
  _empresa: any
  disponibilidades: any = [];
  podeEditarPrecos: boolean;
  @Input() set empresa(value: any) {
    this._empresa = value

    if(this.catalogo)
      this.atualizeEditarProdutos()
  };

  get empresa(): any {
    return this._empresa
  }

  disponibilidadePorEmpresa: boolean;

  @Input() set catalogo(value: any) {
    this._catalogo = value;

    if(this.empresa)
      this.atualizeEditarProdutos()


    if( this.catalogo && (this.catalogo.id === 1 || this.catalogo.id === 657) ) {
      this.pageSize = 50;
    }


    console.log("Catalogo setado no produto", this._catalogo)
    let categorias = value ? value.categorias : null
    const categoriasDoCatalogo = Object.assign([], categorias);

    categoriasDoCatalogo.sort( (a: any, b: any) =>  this.getPosicao(a) - this.getPosicao(b));

    this.categoriasDoCatalogo = categoriasDoCatalogo;
    this.categoriasDoCatalogo.unshift(this.categoriaTodas);

    this.categoriaSelecionada = this.categoriaTodas;

    if(this._catalogo) {
      this.disponibilidadePorEmpresa = this.catalogo.disponibilidadePorEmpresa;
      this.empresasService.obtenhaTemplates(this._catalogo).then( (templates) => {
        this.templates = templates || []
        this.carregueProdutos().then( () => { });
      });
      this.disponibilidades = this.disponibilidadeService.obtenhaDisponibidadesComDescricao(this._catalogo.disponibilidades)
    }
  }

  get catalogo(): any {
    return this._catalogo;
  }

  constructor(private produtoService: ProdutoService, private connstantsService: ConstantsService,
              private autorizacaoService: AutorizacaoService,  private disponibilidadeService: DisponibilidadeService,
              private httpCliente: HttpClient,
              private dialogService: DialogService, private empresasService: EmpresasService) {
    this.largura = (window.innerWidth - 5) + 'px';

    if( window.innerWidth > 600 ) {
      this.largura = '100%';
    }
  }

  ngOnInit() {
    this.usuario = this.autorizacaoService.getUser() || {};

    this.produtoService.listeTagsAlimentar().then((resposta) => {
      this.tagsAlimentar = resposta;
    })
  }

  private temEstoque(){
    return (this.estoqueSelecionado === this.produtosPorEstoque[0].nome)
  }

  carregueProdutos(inicio: number = 0, texto: string = null) {
    this.carregando = true;

    let q = texto ? texto : this.filtro.texto;

    const mapCategorias = {};
    const mapCategoriasDoCatalogo = {};

    for(let categoria of this.categoriasDoCatalogo)
      mapCategoriasDoCatalogo[categoria.id] = categoria

    return this.produtoService.liste(this.catalogo.id, this.temEstoque(), inicio, this.pageSize, q,
      this.categoriaSelecionada, this.filtroDisponibilidades, this.filtro.semCodigoPdv,
      this.empresa.id ? null : true, null, null, null, this.filtro.naoSincronizados).then( (respProdutos) => {
      this.buscouComFiltro = q && (q !== '');

      this.produtos = respProdutos.produtos || [];
      this.total = respProdutos.total;


      this.produtos.forEach( produto => {
        if (!produto.categoria) {
          produto.categoria = {
            id: -1,
            nome: 'Outros',
            posicao: 10000,
            disponivel: true
          }
        }

        let valorDisponibilidadeProduto = this.catalogo.disponibilidadePorEmpresa && produto.produtoNaEmpresa &&
          produto.produtoNaEmpresa.disponibilidade != null ?
          produto.produtoNaEmpresa.disponibilidade
          : produto.disponibilidade
        const disponibilidade = CadProdutoComponent.DISPONIBILIDADES[valorDisponibilidadeProduto];

        produto.estahDisponivel = (disponibilidade != null) ? disponibilidade.disponivel : true;

        if(!mapCategoriasDoCatalogo[produto.categoria.id]) {
          mapCategoriasDoCatalogo[produto.categoria.id] = produto.categoria; //se entrar aqui, tem produto com categoria fora do catálogo
          console.log("** Produto " + produto.nome + " tem categoria fora do catálogo: " + produto.categoria.id)
        }
        else {
          produto.categoria =  mapCategoriasDoCatalogo[produto.categoria.id];

        }

        let erro: string = this.obtenhaErroNaoDisponivel(produto)

        if(erro){
          produto.disponibilidade = -1;
          produto.erroDisponivel = erro;
        }

        mapCategorias[produto.categoria.id] = produto.categoria;

      });

      this.produtosPorCategoria = _.groupBy(this.produtos, produto => produto.categoria.id);

      this.categorias = Object.values(mapCategorias).sort( (a: any, b: any) =>  a.posicao - b.posicao);
      this.carregando = false;

      return Promise.resolve();
    }).catch( (erro) => {
      this.carregando = false;
    });
  }

  private obtenhaErroNaoDisponivel(produto) {
    let semAdicionais = (!produto.camposAdicionais || produto.camposAdicionais.length === 0);
    let produtoSemValor = produto.novoPreco != null ? produto.novoPreco === 0 : produto.preco === 0;

    if(produtoSemValor && semAdicionais) return "produto sem valor"

    if(semAdicionais) return;

    let camposAdicionaisObrigatorios: any  = produto.camposAdicionais.filter((item: any) => item.obrigatorio);

    if(produtoSemValor && !camposAdicionaisObrigatorios.length) return "produto sem valor e adicional obrigatorio"

    let adicionalObrigatorioSemDisponivel: any, adicionalObrigatorioSemValor, adicionalObrigatorioComAlgumSemValor;

    let temAdicionalObrigatorio = false;

    for(let adicional of camposAdicionaisObrigatorios){
      let opcoesDisponiveis: any =
        adicional.opcoesDisponiveis ? adicional.opcoesDisponiveis.filter((opcao: any) => opcao.disponivel ) : [];

      if( opcoesDisponiveis.length === 0) {
        adicionalObrigatorioSemDisponivel = adicional;
        continue;
      }

      let opcoesComValor: any =  opcoesDisponiveis.filter((opcao: any) => opcao.valor );

      if( opcoesComValor.length === 0 && produtoSemValor)
          adicionalObrigatorioSemValor = adicional;

      let temOpçaoSemValor = opcoesDisponiveis.find((opcao: any) => !opcao.valor );

      if(temOpçaoSemValor && produtoSemValor ) adicionalObrigatorioComAlgumSemValor = adicional;

      if(opcoesDisponiveis.length && opcoesComValor.length && !temOpçaoSemValor)
        temAdicionalObrigatorio = true;
    }

    if(adicionalObrigatorioSemDisponivel)
      return  `adicional "${adicionalObrigatorioSemDisponivel.nome}" obrigatório sem nenhuma opção disponível`


    if(temAdicionalObrigatorio) return;

    if(adicionalObrigatorioSemValor )
      return  `adicional "${adicionalObrigatorioSemValor.nome}" obrigatório sem nenhuma opção com preço`

    if(adicionalObrigatorioComAlgumSemValor)
      return  `adicional "${adicionalObrigatorioComAlgumSemValor.nome}" obrigatório possui opção sem valor `
  }

  getDescricaoDisponibilidade(id: number){
    let disponibilidade =  this.catalogo.disponibilidades.find((item: any) => item.id === id);

     this.disponibilidadeService.obtenhaDescricao(disponibilidade)


    return disponibilidade.descricao;
  }


  getPosicao(nomeCategoria){
    let _produto = this.produtos.find( produto => produto.categoria && produto.categoria.nome.trim() === nomeCategoria.trim())

    let posicao = _produto ? _produto.categoria.posicao : 1000000;

    return posicao;
  }

  exibaModalProdutosIndisponiveis(event){

    return this.httpCliente.get('/integracaoerp/produtos/faltantes')
      .toPromise().then((resposta: any) => {

        const windowRef: DialogRef = this.dialogService.open({
          title: null,
          content: PayloadJsonViewComponent,
          minWidth: 250,
          width: window.innerWidth > 600 ? 600 : window.innerWidth,
          maxHeight: window.innerHeight - 100,
          cssClass: 'bsModal'
        });

        KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

        windowRef.content.instance.payload = resposta
        windowRef.content.instance.titulo = 'Produtos Faltantes Ecletica';


      }).catch((err) => {
           alert(err)
      });

    if(event){
      event.stopPropagation();
      event.preventDefault();
    }

  }



  importarProdutoCodigoERP(){
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaImportarProdutosERPComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef);

    windowRef.content.instance.informarCodigo = true;
    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result) => {
        if(result)
          this.carregueProdutos().then( () => {});

      },
      (a) => {      });
    //
  }

  importarOpenDelivery(){
    return this.importarProdutosERP(true);
  }

  importarProdutosERP(openDelivery = false){
   //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaImportarProdutosERPComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.catalogo = this.catalogo;

    if(openDelivery)   windowRef.content.instance.openDelivery = true;

    windowRef.result.subscribe( (result) => {
        if(result){
          this.carregueProdutos().then( () => {});
        }
      },
      (a) => {      });
  }

  importarProdutosCatalogoModelo(){

    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaSincronizarCatalogomodeloComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result) => {
        if(result){
          this.carregueProdutos().then( () => {});
        }
      },
      (a) => {      });
  }

  importarProdutosChinna(){
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaSincronizarChinaComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)
    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result) => {
        if(result){
          this.carregueProdutos().then( () => {});
        }
      },
      (a) => {      });
  }



  novoProduto() {

    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      content: CadProdutoComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      cssClass: 'bsModal',
      maxHeight:  window.innerHeight - 100

    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)
    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.templatesPiza = this.templates;
    windowRef.content.instance.tagsAlimentar = this.tagsAlimentar;

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id){
          this.carregueProdutos().then( () => {});
        }
      },
      (a) => {      });
  }

  editarProduto({ sender, rowIndex, dataItem }) {
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadProdutoComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.templatesPiza = this.templates;
    windowRef.content.instance.tagsAlimentar = this.tagsAlimentar;
    windowRef.content.instance.carregueProduto(dataItem);

    windowRef.result.subscribe( (result: any) => {
        if(result && result.id){
          this.carregueProdutos().then( () => {});
        }
      },
      (a) => {      });
  }

  abrirCadTemplatePizza(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: CadTemplatepizzaComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.templates  = this.templates
  }

  sincronizar(opcao){
    if(opcao === 'Preços'){
      this.sincronizarPrecos();
    }

    if(opcao === 'Estoque'){
      this.sincronizarEstoque();
    }
  }

  sincronizarPrecos(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaSincronizarPrecosComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result) => {
        if(result)
          this.carregueProdutos();
      },
      (a) => {      });
  }

  sincronizarEstoque(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaSincronizarEstoqueComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (result) => {
        if(result)
          this.carregueProdutos();
      },
      (a) => {      });

  }


  mudouPaginacao($event: PageChangeEvent) {
    this.pageSize = $event.take;

  }

  abraDialogRemoverProduto($event: RemoveEvent) {
    this.produtoSelecionado = $event.dataItem;
    this.abraDialogo = true;

  }

  fechar() {
    this.abraDialogo = false;
    this.mensagemRemocao = null;
    this.produtoSelecionado = null;
    this.excluindoProduto = false;
  }

  removaProduto() {
    this.excluindoProduto = true;

    this.produtoService.removaProduto(this.produtoSelecionado).then((resposta) => {
      this.carregueProdutos().then ( () => {
        this.removeu = true;
        this.excluindoProduto = false;
        this.mensagemRemocao = "O produto '" + this.produtoSelecionado.nome + "' foi removido ";
        this.produtoSelecionado = null;

      });
    }).catch((reason) => {
      this.excluindoProduto = false;
      this.produtoSelecionado = null;
      this.mensagemRemocao = reason;
    })
  }

  selecionouTipoDeEstoque($event: any) {
    this.estoqueSelecionado = $event.title;
    this.produtos = [];
    this.carregueProdutos().then( () => {});
  }


  importarDoIfood() {
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: TelaSincronizarIfoodComponent,
      minWidth: 250,
      width: window.innerWidth > 900 ? 900 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.catalogo;

    windowRef.result.subscribe( (importou) => {
      if(importou) this.carregueProdutos().then( () => {});
      } );

  }

  ordenarCategorias(){

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: OrdenarCategoriasComponent,
      minWidth: 250,
      width: window.innerWidth > 600 ? 600 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.empresa = this.empresa;

    windowRef.result.subscribe( (categorias: any) => {
        if(categorias && categorias.length)
          this.catalogo.categorias = categorias;
      } );
  }

  onFilter(event: any) {
    this.skip = 0;

    if(this.timerBusca) clearTimeout(this.timerBusca);

    this.timerBusca = setTimeout( () => {
      this.carregueProdutos(0, event.target.value).then( () => {});
    }, 1000);
  }

  subirProduto(produto: any, indice: number, categoria: any) {
    if(this.trocandoOrdem || indice === 0) return;

    this.trocandoOrdem = true;

    const produtos = this.produtosPorCategoria[categoria.id];
    let produtoDesce = produtos[indice - 1],
        ordemDesce = produtoDesce.ordem;

    produto.novoIndice = true;
    produtoDesce.ordem = produto.ordem;
    produto.ordem = ordemDesce;

    this.produtoService.atualizeOrdem(this.catalogo, [produto, produtoDesce]).then( () => {
      produtos.splice(indice, 1);
      produtos.splice(indice  - 1, 0, produto);
      this.trocandoOrdem = false;
    }).catch( (err) => {
      this.trocandoOrdem = false;
      alert(err)
    })
  }

  subir1Posicao(produto: any) {
    this.produtoService.subir1Posicao(this.catalogo, produto).then( (dados) => {
      this.carregueProdutos(this.skip).then( () => {});
    });
  }

  descer1Posicao(produto) {
    this.produtoService.descer1Posicao(this.catalogo, produto).then( (dados) => {
      this.carregueProdutos(this.skip).then( () => {});
    });
  }

  temFiltro() {
    const possuiFiltro = this.filtro.texto != null  && this.filtro.texto !== '';

    return possuiFiltro;
  }

  importarDeArquivoPrecosCSV(){
    //scrollable: true
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ImportadorCsvComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.content.instance.empresa = this.empresa;
    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.somentePrecos = true;


    windowRef.result.subscribe( (importou) => {
        if(importou) this.carregueProdutos().then( () => {});
      } );
  }

  importarDeArquivoCSV() {

    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ImportadorCsvComponent,
      minWidth: 250,
      width: window.innerWidth > 800 ? 800 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)


    windowRef.content.instance.empresa = this.empresa;


    windowRef.result.subscribe( (importou) => {
        if(importou) this.carregueProdutos().then( () => {});
      } );
  }

  loadMore() {
    this.carregueProdutos(this.produtos.length).then( () => {});
  }

  obtenhaAltura(){
    return window.innerHeight - 300;
  }

  obtenhaImagem(contact: any) {

  }

  busqueMaisProdutos() {
  }

  busqueProdutos() {
    setTimeout( () => {
      this.produtos = [];
      this.skip = 0;
      this.carregueProdutos(this.produtos.length).then( () => {});
    }, 0);
  }

  clicouSubir(produto: any, indice: number, categoria: any, opcao: any) {
    if( opcao.valor === 'SUBIR_1' ) {
      this.subir1Posicao(produto);
    } else if( opcao.valor === 'SUBIR_TOPO' ) {
      this.produtoService.subirParaTopo(this.catalogo, produto).then( (dados) => {
        const produtosCategoria = this.produtosPorCategoria[categoria.id];

        for( let i = 0; i < produtosCategoria.length; i++ ) {
          const produtoCorrente = produtosCategoria[i];

          if( produtoCorrente.ordem < produto.ordem ) {
            produtoCorrente.ordem ++;
          }
        }

        produto.ordem = dados.ordem;

        produtosCategoria.sort( (a, b) => a.ordem - b.ordem);
      }).catch( erro => {
        alert('erro');
      });
    }
  }

  clicouDescer(produto: any, indice: number, categoria: any, opcao: any) {
    if( opcao.valor === 'DESCER_1' ) {
      this.descer1Posicao(produto);
    } else if( opcao.valor === 'DESCER_TUDO' ) {
      this.produtoService.descerParaFinal(this.catalogo, produto).then( (dados) => {
        const produtosCategoria = this.produtosPorCategoria[categoria.id];

        for( let i = 0; i < produtosCategoria.length; i++ ) {
          const produtoCorrente = produtosCategoria[i];

          if( produtoCorrente.ordem > produto.ordem ) {
            produtoCorrente.ordem --;
          }
        }

        produto.ordem = dados.ordem;

        produtosCategoria.sort( (a, b) => a.ordem - b.ordem);
      });
    }
  }

  public expandDetailsBy = (dataItem: any): any  => {
    return true;
  }

  onDisabledChange(disabled: boolean): void {
    this.visible = !disabled;
  }

  public mostrarComplementos(dataItem: any, index: number): boolean {
    return this.buscouComFiltro ? dataItem.camposAdicionais.length > 0 : false;
  }

  ativarCategoria(categoria: any) {
    this.processando = true;
    this.produtoService.pausarAtivarCategoria(this.catalogo, categoria, true).then( (resposta: any) => {
      this.processando = false;
      categoria.disponivel = true;
    }).catch( erro => {
      this.processando = false;
    });
  }

  pausarCategoria(categoria: any) {
    this.processando = true;

    this.produtoService.pausarAtivarCategoria(this.catalogo, categoria, false).then( (resposta: any) => {
      this.processando = false;
      categoria.disponivel = false;
    }).catch( erro => {
      this.processando = false;
    });
  }

  pausarVendasProduto(categoria: any, produto: any) {
    this.processando = true;
    this.definirDisponibilidade(produto, CadProdutoComponent.DISPONIBILIDADES[2].id);

    this.produtoService.salveDisponibilidadeProduto(this.catalogo, produto).then( resposta => {
      this.processando = false;
      this.removaProdutoDaGrid(categoria, produto);
    }).catch( (erro) => {
      this.processando = false;
    });
  }

  definirDisponibilidade(produto, disponibilidade) {
    if(this.empresa && this.empresa.id && this.catalogo.disponibilidadePorEmpresa) {
      if(!produto.produtoNaEmpresa){
        produto.produtoNaEmpresa = {}
      }
      produto.disponibilidadeNaEmpresa =  disponibilidade;
      }
    else  {
      delete produto.disponibilidadeNaEmpresa
      produto.disponibilidade = disponibilidade;
    }


  }

  ativarVendasProduto(categoria, produto: any) {
    this.processando = true;


    if( produto.disponibilidades && produto.disponibilidades.length > 0 )
      this.definirDisponibilidade(produto, CadProdutoComponent.DISPONIBILIDADES[1].id);
    else
      this.definirDisponibilidade(produto, CadProdutoComponent.DISPONIBILIDADES[0].id);


    this.produtoService.salveDisponibilidadeProduto(this.catalogo, produto).then( resposta => {
      this.processando = false;
      this.removaProdutoDaGrid(categoria, produto);
    }).catch( (erro) => {
      this.processando = false;
    });
  }

  private removaProdutoDaGrid(categoria, produto) {
      const listaProdutos = this.produtosPorCategoria[categoria.id];

      const indice = listaProdutos.indexOf(produto);

      if(indice !== -1) {
        listaProdutos.splice(indice, 1);
      }
  }

  onPageChange($event: PageChangeEvent) {
    this.skip = $event.skip;
    this.pageSize = $event.take;

    window.scrollTo(0, 0);
    this.carregueProdutos($event.skip).then( () => {});
  }


  abraModalExportarProdutos(){
    const windowRef: DialogRef = this.dialogService.open({
      title: null,
      content: ModalExportarProdutosComponent,
      minWidth: 800,
      width: window.innerWidth > 1200 ? 1200 : window.innerWidth,
      maxHeight: window.innerHeight - 100,
      cssClass: 'bsModal'
    });


    windowRef.content.instance.catalogo = this.catalogo;
    windowRef.content.instance.temEstoque  = this.temEstoque()

    KendoPopupUtils.abriuPopupNgBootstrap(windowRef)

    windowRef.result.subscribe((result: any) => { });

    return false;
  }


  downloadCardapioPDF() {
    window.open(`/images/empresa/pdf/cardapio${this.catalogo.id}.pdf?v=` + new Date().getTime(), '_BLANK');
  }

  confirmeReornedarProdutos(){
    this.confirmarReordenacao = true;
  }
  respostaConfirmacaoReordenacao(resposta){
    delete this.confirmarReordenacao
    if(resposta)
      this.ordeneProdutosPorNome();

  }

  ordeneProdutosPorNome() {
    this.ordenando = true;
    this.produtoService.recalculeOrdemPorNome(this.catalogo).then( () => {
      this.ordenando = false;
      this.carregueProdutos().then( () => {});
    }).catch( (erro) => {
      this.ordenando = false;
      alert(erro)
    })
  }


  clonarProduto(produto: any) {
    this.processando = true
    this.produtoService.cloneProduto(produto.id, this.catalogo).then((resultado) => {
      alert('O produto ' + produto.nome + ' foi clonado com sucesso');
      this.processando = false
      this.carregueProdutos().then(() => {});
    }).catch((erro) => {
      this.processando = false
      alert(erro)
    })
  }

  sliderChange(pageIndex: number){
    this.skip = (pageIndex - 1) * this.pageSize;

    alert(this.pageSize)

    this.carregueProdutos();


  }

  sincronizarComParceiros() {
    if(!this.empresa.integracaoDelivery) return false;

     let sistema = this.empresa.integracaoDelivery.sistema;

     return sistema === 'ecletica' ||  sistema === 'bluesoft'  || sistema === 'gcom' || sistema === 'totvs'
  }

  private atualizeEditarProdutos() {
    if(!this.catalogo || !this.catalogo.compartilhado) {
      this.podeEditarProdutos = true
      this.podeEditarPrecos = true
      return;
    }



    this.podeEditarProdutos = !this.empresa || !this.empresa.id

    this.podeEditarPrecos = this.podeEditarProdutos || (this.catalogo && this.catalogo.precoPorEmpresa
      && RotaGuardClient.alterarPrecoCardapioCompartilhado(this.autorizacaoService.getUser()));

  }


}
